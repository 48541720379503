export default [
    {
        title: "ادمین",
        name: "AdminIndexPage",
        icon: "CogIcon",
        children: [
            {
                title: "مدیریت کارکنان",
                name: "AdminUserPage",
                icon: "UsersIcon"
            },
            {
                title: "مدیریت انبارها",
                name: "AdminInventoryPage",
                icon: "OfficeBuildingIcon"
            },
            {
                title: "دسته بندی ها",
                name: "AdminCategoryPage",
                icon: "CollectionIcon"
            },
            {
                title: "محصولات",
                name: "AdminProductPage",
                icon: "TruckIcon"
            },
            {
                title: "برند ها",
                name: "AdminBrandPage",
                icon: "CubeTransparentIcon"
            },
            {
                title: "مشخصات کالا",
                name: "AdminSpecPage",
                icon: "InformationCircleIcon"
            },
            {
                title: "سوالات متداول",
                name: "AdminFAQPage",
                mobile: "mobile",
                icon: "QuestionMarkCircleIcon"
            },

        ]
    },
    {
        title: "مدیر انبار",
        name: "InventoryManagerIndexPage",
        icon: "OfficeBuildingIcon",
        children: [
            {
                title: "مدیریت کارکنان",
                name: "InventoryManagerUserList",
                icon: "UsersIcon"
            },
            {
                title: "سفارشات",
                name: "InventoryManagerInvoiceList",
                icon: "ClipboardListIcon"
            },
            {
                title: "مرجوعی ها",
                name: "InventoryManagerInvoiceReturnList",
                icon: "ExternalLinkIcon"
            },
            {
                title: "پالت های ردیابی",
                name: "InventoryManagerStockList",
                icon: "InboxIcon"
            },
            {
                title: "کمپین ها",
                name: "InventoryManagerCampaignList",
                icon: "ReceiptTaxIcon"
            },
            {
                title: "حواله ها",
                name: "InventoryManagerDocumentList",
                icon: "DocumentIcon"
            },
            {
                title: "انبار ضایعات",
                name: "InventoryManagerDefectList",
                icon: "InboxIcon"
            },
            {
                title: "سابقه ارسال به مرکز",
                name: "InventoryManagerTransferToCentral",
                icon: "InboxIcon"
            },
            {
                title: "گزارش مرور",
                name: "InventoryManagerReportPage",
                icon: "DocumentIcon"
            },
            {
                title: "تقویم روزهای تعطیل",
                name: "InventoryManagerHolidayCalendar",
                icon: "CalendarIcon"
            },
            {
                title: "اعلامیه قیمت",
                name: "InventoryManagerPriceList",
                icon: "CurrencyDollarIcon"
            },
        ]
    },
    {
        title: "مدیر کیفی",
        name: "QCManagerIndexPage",
        icon: "ShieldCheckIcon",
        children: [
            {
                title: "پالت های ردیابی",
                name: "QCManagerStockList",
                icon: "InboxIcon",
            }
        ]
    },
    {
        title: "مدیر توزیع",
        name: "DistributorManagerDashboard",
        icon: "UsersIcon",
        children: [
            {
                title: "مدیریت ماشین ها",
                name: "DistributorManagerVehicleList",
                icon: "TruckIcon"
            },
            {
                title: "مدیریت موزعین",
                name: "DistributorManagerUserList",
                icon: "UsersIcon"
            },
            {
                title: "لیست شیفت ها",
                name: "ShiftList",
                icon: "CollectionIcon"
            },
            {
                title: "تخصیص به موزعین",
                name: "DistributorManagerDeliveryList",
                icon: "TruckIcon"
            },
            {
                title: "مدیریت تخصیص ها",
                name: "DistributorAssignmentList",
                icon: "UserCircleIcon"
            },
            {
                title: "نقشه موزعین",
                name: "DistributorManagerDistributorMap",
                icon: "MapIcon"
            },
            {
                title: "بازه های زمانی تولید شده",
                name: "InventoryManagerDeliveryDatetimeList",
                icon: "ClockIcon"
            },
        ]
    },
    {
        title: "سفارش ساز",
        name: "InventoryPackerIndexPage",
        icon: "ArchiveIcon",
        children: [
            {
                title: "سفارشات",
                name: "InventoryPackerInvoiceList",
                icon: "ClipboardListIcon",
            }
        ]
    },
    {
        title: "کنترلر سفارشات",
        name: "InventoryControllerIndexPage",
        icon: "ClipboardListIcon",
        children: [
            {
                title: "سفارشات",
                name: "InventoryControllerInvoiceList",
                icon: "ClipboardListIcon",
            }
        ]
    },
    {
        title: "موزع",
        name: "DistributorIndexPage",
        icon: "TruckIcon",
        children: [
            {
                title: "دریافت از انبار",
                name: "DistributorInvoiceListInventory",
                icon: "OfficeBuildingIcon"
            },
            {
                title: "تحویل به مشتری",
                name: "DistributorInvoiceListDelivery",
                icon: "TruckIcon"
            },
            {
                title: "مرجوعی ها",
                name: "DistributorInvoiceReturnList",
                icon: "ExternalLinkIcon"
            }
        ]
    },
    {
        title: "پشتیبان فروش",
        name: "SupporterIndexPage",
        icon: "PhoneIcon",
        children: [
            {
                title: "همه سفارشات",
                name: "SupporterInvoiceListAll",
                icon: "ClipboardListIcon"
            },
            {
                title: "سفارشات در انتظار",
                name: "SupporterInvoiceListPending",
                icon: "ClipboardListIcon"
            },
            {
                title: "شکایات",
                name: "SupporterComplaintPage",
                icon: "SupportIcon"
            },
            {
                title: "همه مرجوعی ها",
                name: "SupporterInvoiceReturnListAll",
                icon: "ExternalLinkIcon"
            },
            {
                title: "مرجوعی های در انتظار",
                name: "SupporterInvoiceReturnListPending",
                icon: "ExternalLinkIcon"
            },
            {
                title: "نظرات",
                name: "SupporterInvoiceCommentList",
                icon: "AnnotationIcon"
            },

        ]
    },
    {
        title: "انباردار",
        name: "InventoryStaffIndexPage",
        icon: "InboxIcon",
        children: [
            {
                title: "بررسی مرجوعی",
                name: "InventoryStaffInvoiceReturnList",
                icon: "ExternalLinkIcon",
            }
        ]
    },
    {
        title: "مسئول ترابری",
        name: "InventoryCarrierIndexPage",
        icon: "ShoppingCartIcon",
        children: [
            {
                title: "چیدمان مرجوعی",
                name: "InventoryCarrierInvoiceReturnList",
                icon: "ExternalLinkIcon",
            },
            {
                title: "جابجایی سفارشات",
                name: "InventoryCarrierInvoiceList",
                icon: "ClipboardListIcon",
            }
        ]
    },
    {
        title: "بازرس کیفی",
        name: "QCStaffIndexPage",
        icon: "BadgeCheckIcon",
        children: [
            {
                title: "مرجوعی ها",
                name: "QCStaffInvoiceReturnList",
                icon: "ExternalLinkIcon",
            }
        ]
    },
    {
        title: "مدیر مالی",
        name: "FinancialManagerIndexPage",
        icon: "CashIcon",
        children: [
            {
                title: "درخواست تسویه",
                name: "FinancialManagerWalletRequest",
                icon: "CreditCardIcon",
            }
        ]
    }
]