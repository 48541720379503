<template>
    <div class="intro-y flex items-center justify-between flex-wrap">
      <h1 class="text-lg cursor-pointer" @click="showFilter = !showFilter">سابقه ارسال به انبار مرکزی</h1>

      <VButton class="!w-auto intro-y order-1 lg:order-2" :disabled="true"
             @click="returnModal = true">
      برگشت از انتقال
    </VButton>
    </div>
  
    <div class="intro-y flex mt-4 lg:w-1/2 xl:w-1/3" v-if="showFilter">
      <VInput placeholder="جستجو" v-model.lazy="filter.q" @input="this.filter.page = 1" shadow/>
    </div>
  
    <VPagination
        class="my-4 justify-center md:justify-start"
        v-model="filter.page"
        :pages="Transfer.PageCount"
        :range-size="1"
        active-color="#1d4ed8"
    />
  
    <VCard class="!bg-transparent mt-4 text-slate-600" noPadding scrollable>
      <div ref="table"></div>
    </VCard>
  
    <TransferStockDefectModal
        v-model="returnModal"
        :stock-defects="selectedStockDefects"
        @submitted="returnModal = false; getTransferToCentral()"/>
  
  </template>
  
  <script>
  import VCard from "@/components/General/VCard";
  import VPagination from "@hennge/vue3-pagination";
  import {getTransferToCentral} from "@/API/API";
  import {TabulatorFull as Tabulator} from "tabulator-tables";
  import TransferStockDefectModal from "@/components/Modal/InventoryManager/TransferStockDefectModal";
  import VButton from "@/components/General/VButton";
  import VInput from "@/components/General/VInput.vue";
  
  export default {
    name: "TransferToCentral",
    components: {VInput, VButton, TransferStockDefectModal, VCard, VPagination},
    data() {
      return {
        Transfer: [],
  
        returnModal: false,
        selectedStockDefects: '',
        showFilter: true,
  
        firstCall: 1,
        filter: {
          q: '',
          page: 1,
        }
      }
    },
    created() {
      this.filter.selectedInventory = this.selectedInventory;
      this.getTransferToCentral()
    },
    mounted() {
      this.initTable();
      this.tabulator.on("rowSelectionChanged", data => this.selectedStockDefects = data)
    },
    methods: {
      async getTransferToCentral() {
        this.tabulator?.alertManager.alert('در حال بارگذاری ....')
        this.Transfer = (await getTransferToCentral(this.filter, this.firstCall)).data.data
        this.tabulator.alertManager.clear();
        this.refreshTable()
      },
      refreshTable() {
        this.tabulator.setData(this.Transfer.Transfer)
        window.scrollTo(0, 0)
      },
      initTable() {
        this.tabulator = new Tabulator(this.$refs.table, {
          selectable: true,
          layout: "fitDataFill",
          responsiveLayout: "collapse",
          columns: [
            {
              formatter: "responsiveCollapse",
              width: 40,
              minWidth: 30,
              hozAlign: "center",
              resizable: false,
              headerSort: false
            },
            {
              title: 'شناسه پالت',
              field: 'Stock.StockId',
            },
            {
              title: 'محصول',
              field: 'Name',
              vertAlign: 'middle',
              formatter: cell => {
                return `<div class="flex items-center">
                          <img src="${this.$helper.imageURL(cell.getData().Stock.Product.ImagePath)}" alt="" class="${cell.getData().Stock.Product.ImagePath ? 'w-10 h-10' : ''}">
                          <span class="whitespace-pre-wrap normal-digits ${cell.getData().Stock.Product.ImagePath ? 'mr-2' : ''}">${cell.getData().Stock.Product.Name}</span>
                        </div>`
              }
            },
            {
              title: 'نوع انتقال',
              field: 'Destination',
              vertAlign: 'middle',
              formatter: cell => cell.getData().Destination.Name 
            },
            {
              title: 'تعداد',
              field: 'Amount',
              vertAlign: 'middle',
              formatter: cell => cell.getData().Amount + ' عدد'
            },
            {
              title: 'تاریخ ثبت',
              field: 'CreateDate',
              vertAlign: 'middle'
            },
          ],
        });
      }
    },
    watch: {
      filter: {
        deep: true,
        handler() {
          if (this.firstCall === 0) {
            this.getTransferToCentral()
            this.$router.replace({
              name: this.$route.name,
              query: this.filter
            })
          }
  
          this.firstCall = 0
        }
        // handler() {
        //   this.getTransferToCentral()
        // }
      },
    }
  }
  </script>
  
  <style scoped>
  
  </style>