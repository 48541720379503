<template>
  <ModalView title="انتخاب وضعیت مرجوعی" @opened="modalOpened">

    <form @submit.prevent="submit">

      <!-- State -->
      <VSelect class="mt-4" v-model.number="editInvoiceReturn.stateLId" required>
        <option value="" disabled selected>وضعیت</option>
        <option
            v-for="state in states"
            :key="state.LookupId"
            :value="state.LookupId">
          {{ state.Name }}
        </option>
      </VSelect>

      <!-- Supporter Confirm -->
      <div v-if="editInvoiceReturn.stateLId === $helper.invoiceReturnState.SUPPORTER_CONFIRM">
        <h1 class="mt-4">زمان تحویل</h1>

        <Multiselect
            dir="rtl"
            class="mt-2"
            v-model="deliveryTimes.DeliveryDateTimeId"
            :options="deliveryTimes"
            placeholder="زمان تحویل"
            :searchable="true"
            required/>
      </div>

      <!-- Supporter Reject -->
      <div v-else-if="editInvoiceReturn.stateLId === $helper.invoiceReturnState.SUPPORTER_REJECT">
        <!-- Reject Reason -->
        <Multiselect
            dir="rtl"
            class="mt-4"
            v-model.number="editInvoiceReturn.rejectReasonLId"
            :options="rejectReasons"
            placeholder="دلیل رد"
            :searchable="true"
            required/>
        <VInput class="mt-4" placeholder="توضیح" v-model="editInvoiceReturn.rejectReason"/>
      </div>

      <VButton class="mt-4" type="submit" :loading="$loading.value">ثبت</VButton>

    </form>

  </ModalView>
</template>

<script>
import ModalView from "@/components/Modal/General/ModalView";
import VSelect from "@/components/General/VSelect";
import VButton from "@/components/General/VButton";
import {cancelInvoiceReturn, getLookupCombos, submitInvoiceReturn} from "@/API/API";
import VInput from "@/components/General/VInput";
import Multiselect from "@vueform/multiselect";

export default {
  name: "EditInvoiceReturnModal",
  props: ['invoiceReturn'],
  emits: ['submitted'],
  components: {VInput, VButton, VSelect, ModalView, Multiselect},
  data() {
    return {
      states: [],
      rejectReasons: [],
      deliveryTimes: [],
      // DeliveryDate: '',

      editInvoiceReturn: {
        items: [],
        stateLId: '',
        deliveryDateTimeId: '',
        rejectReasonLId: '',
        rejectReason: ''
      }
    }
  },
  computed: {
    canComplete() {
      return this.invoiceReturn.Items.reduce((errorCount, item) => {
        if (item.HealthyAmount !== 0 || item.DefectAmount !== 0) {
          return ++errorCount
        }
        return errorCount
      }, 0) === 0
    },
    canSubmit() {
      return this.invoiceReturn.Items.reduce((errorCount, item) => {
        if (item.HealthyAmount === null || item.DefectAmount === null || item.NoNeedAmount === null)
          return ++errorCount
        return errorCount
      }, 0) === 0
    }
  },
  methods: {
    async getData() {
      let data = (await getLookupCombos(103080401)).data.data
      this.states = data.Lookups.InvoiceReturnStates;
      this.rejectReasons = data.Lookups.DenyReasonStates.map(time => ({
        label: time.Name,
        value: time.LookupId,
      }));

      this.deliveryTimes = data.DeliveryDateTimes.map(time => ({
        label: time.Description,
        value: time.DeliveryDateTimeId,
        disabled: !time.CanChoose
      }));

    },
    submit() {
      if (this.editInvoiceReturn.stateLId === this.$helper.invoiceReturnState.DELIVERD && !this.canComplete) {
        this.$errorModal.value = 'مقادیر ورودی را چک کنید، کالا قابل بازگشت (سالم یا ضایعاتی) وجود دارد.'
        return
      } else if (this.editInvoiceReturn.stateLId === this.$helper.invoiceReturnState.SUPPORTER_CONFIRM && !this.canSubmit) {
        this.$errorModal.value = 'لطفا تمام مقادیر سالم، ضایعاتی و بدون نیاز به بازگشت را با توجه به تعداد کل مرجوعی هر محصول، تکمیل کنید.'
        return
      } else if (this.editInvoiceReturn.stateLId === this.$helper.invoiceReturnState.SUPPORTER_CONFIRM && this.canComplete) {
        this.$errorModal.value = 'مقادیر ورودی را چک کنید، کالایی (سالم یا ضایعاتی) برای بازگشت وجود ندارد.'
        return
      }

      this.editInvoiceReturn.items.forEach(item => item.StockDetailId = item.StockDetail.StockDetailId)
      const allowed = ['InvoiceReturnDetailId', 'Amount', 'HealthyAmount', 'DefectAmount', 'NoNeedAmount', 'StockDetailId'];
      this.editInvoiceReturn.items = this.$helper.filterObjectArray(this.editInvoiceReturn.items, allowed)

      this.editInvoiceReturn.deliveryDateTimeId = this.deliveryTimes.DeliveryDateTimeId;
      if (this.editInvoiceReturn.stateLId === this.$helper.invoiceReturnState.SUPPORTER_REJECT) {
        cancelInvoiceReturn(this.$route.params.id, this.editInvoiceReturn)
            .then(resp => {
              this.$infoModal.value = resp.data.message
              this.$emit('submitted')
            })
      } else
        submitInvoiceReturn(this.$route.params.id, this.editInvoiceReturn)
            .then(resp => {
              this.$infoModal.value = resp.data.message
              this.$emit('submitted')
            })
    },
    modalOpened() {
      this.editInvoiceReturn.items = this.$helper.cloneObject(this.invoiceReturn.Items)
      this.editInvoiceReturn.stateLId = ''
      this.getData()
    },
  }
}
</script>

<style scoped>

</style>