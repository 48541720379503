<template>
  <ModalView @closed="defects = []" @opened="getData">
    <form @submit.prevent="submit">
      <!-- Products -->
      <div class="border-b pb-2"
           v-for="(stockDefect, index) in stockDefects"
           :key="stockDefect.StockDefectId">
        <div class="flex justify-between items-center mt-4">
          <img :src="$helper.imageURL(stockDefect.Stock.Product.ImagePath)" alt="" class="w-10 h-10">
          <span class="ml-auto mr-2">{{ stockDefect.Stock.Product.Name }}</span>
          <span class="mx-4">{{ stockDefect.TransfrableAmount }} عدد</span>
          <VInput type="number" class="w-20" placeholder="تعداد" :max="stockDefect.TransfrableAmount"
                  :data-index="index" ref="input" required :min="1"/>
        </div>
      </div>
      <!-- Footer -->
      <div class="flex items-center mt-4">
        <VSelect v-model="destination" required>
          <option
              v-for="destination in destinationsList"
              :key="destination.LookupId"
              :value="destination.LookupId">
            {{ destination.Name }}
          </option>
        </VSelect>
        <VButton class="mr-4 ">ثبت</VButton>
      </div>

    </form>
  </ModalView>
</template>

<script>
import ModalView from "@/components/Modal/General/ModalView";
import VButton from "@/components/General/VButton";
import VInput from "@/components/General/VInput";
import VSelect from "@/components/General/VSelect";
import {getLookupCombos, transferStockDefects} from "@/API/API";

export default {
  name: "TransferStockDefectModal",
  components: {VSelect, VInput, VButton, ModalView},
  props: ['stockDefects'],
  emits: ['submitted'],
  data() {
    return {
      defects: [],
      destinationsList: [],
      destination: ''
    }
  },
  methods: {
    async getData() {
      this.destinationsList = (await getLookupCombos(103020600)).data.data.Lookups.Lookups35
    },
    submit() {
      this.defects = []
     
      // this.$refs.input.forEach(input => this.defects.push({
      //   StockId:this.stockDefects[input.$el.dataset.index].StockId,// this.stockDefects[input.$el.dataset.index].StockDefectId,
      //   //TrackingId: this.stockDefects[input.$el.dataset.index].Stock.TrackingId,
      //   Amount: input.$el.firstChild.value
      // }));

this.$refs.input.forEach(input => this.defects.push({
        StockId: this.stockDefects[input.$el.dataset.index].Stock.StockId,
        Amount: input.$el.firstChild.value
      }));

      transferStockDefects(this.destination, this.defects)
          .then(resp => {
            this.$infoModal.value = resp.data.message
            this.$emit('submitted')
          })
    }
  }
}
</script>

<style scoped>

</style>