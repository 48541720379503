<template>
  <ModalView title="مرجوعی سفارش" @opened="$nextTick(initAudioDropzone)">

    <form ref="form" @submit.prevent="sendBack">

      <div id="dropzone-audio" class="mt-4 dropzone flex overflow-x-scroll !p-4"></div>

      <VInput class="mt-4" v-model="sentBack.description" placeholder="توضیحات" />

      <VButton class="mt-4" type="submit">ثبت</VButton>
    </form>

  </ModalView>
</template>

<script>
import ModalView from "@/components/Modal/General/ModalView";
import VButton from "@/components/General/VButton";
import {createInvoiceReturn} from "@/API/API";
import {Dropzone} from "dropzone";
import VInput from "@/components/General/VInput.vue";

export default {
  name: "ConfirmInvoiceReturnModal",
  props: ['invoice'],
  emits: ['returned', 'error'],
  components: {VInput, VButton, ModalView},
  data() {
    return {
      sentBack: {
        stockDetails: [],
        audioId: '',
        invoiceId: '',
        description: '',
      },
    }
  },
  methods: {
    sendBack() {
      // Create Sent Backs to return them back
      this.invoice.Items.forEach(item => {
        item.StockDetails.forEach(stockDetail => {
          if (stockDetail.SentBack)
            this.sentBack.stockDetails.push(stockDetail.SentBack)
        })
      })

      this.sentBack.stockDetails.forEach(stockDetail => stockDetail.Images = stockDetail.Images.map(image => image.JCoFileId))
      this.sentBack.invoiceId = this.invoice.InvoiceId

      createInvoiceReturn(this.sentBack)
          .then(resp => {
            this.$emit('returned')
            this.$infoModal.value = resp.data.message
           
            this.$router.replace({
              name: 'DistributorInvoiceReturnDetail',
              params: {id: resp.data.data.InvoiceReturnId},
              query: {return: this.sentBack.invoiceId}
            })
          })
          .catch(err => this.$emit('error', err))
    },
    initAudioDropzone() {
      //console.log('gerer')
      let dropzone = new Dropzone("#dropzone-audio", {
        url: process.env.VUE_APP_STAFF_URL + 'user/file',
        headers: {'Authorization': this.$store.getters.token},
        maxFilesize: 1,
        dictFileTooBig: 'حداکثر حجم عکس 1 مگابایت میباشد.',
        dictDefaultMessage: 'انتخاب صدا',
        dictCancelUpload: 'لغو',
        dictRemoveFile: 'حذف',
        dictMaxFilesExceeded: 'حداکثر تعداد صدا 1 عدد است.',
        acceptedFiles: 'audio/*',
        addRemoveLinks: true,
        maxFiles: 1,
        sending(file, xhr, formData) {
          formData.append('type', 'audio') // type audio (2)
        },
        success: (file, response) => {
          file.JCoFileId = response.JCoFileId;
          this.sentBack.audioId = response.JCoFileId
        },
      });

      // Delete Audio
      dropzone.on("removedfile", () => this.complaint.audioId = '')
    }
  },
}
</script>

<style scoped>

</style>