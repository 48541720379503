<template>
  <teleport to="body">
    <div id="invoice-print"
         class="absolute inset-0 w-screen hidden bg-white  text-[0.6rem] print:block ">
      <div>
        <div class="grid grid-cols-1 p-1">
            <h6 class="text-sm bg-gray-800 text-white py-1 text-center mt-4">فروشگاه جونیکو</h6>
          </div>
        <div class="flex items-start">
         
          <div class="text-center">
            <span>{{ invoice.InvoiceId }}</span>
            <VueQrcode :value="String(invoice.InvoiceId)" :options="{ width: 70 }"/>
          </div>
         
          <div class="grid grid-cols-2 border border-black rounded-md p-1 flex-1">
            <span>نام مشتری: </span>
            <span v-if="invoice.User">{{ invoice.User.Name }}</span>

            <span>تاریخ تحویل: </span>
            <span>{{ invoice.DeliveryDateTime?.DeliveryDate }}</span>

            <span>ساعت تحویل: </span>
            <span v-if="invoice.DeliveryDateTime?.Description">{{ invoice.DeliveryDateTime?.Description }}</span>


            <div class="col-span-2">
              <span>آدرس: </span>
              <span v-if="invoice.Address">{{ invoice.Address.FullAddress }}</span>
              <span v-if="invoice.Address?.ExtraInfo"> - {{ invoice.Address.ExtraInfo }}</span>
            </div>
          </div>

        </div>

        <h6 class="text-sm bg-gray-800 text-white py-1 text-center mt-4">محصولات</h6>
        <table class=" border border-black w-full text-gray-900">
          <thead class="text-center border-b border-gray-600" style="display: table-row-group">
          <tr>
            <td class="border-l border-gray-600 py-1">کالا</td>
            <td class="border-l border-gray-600">تعداد</td>
            <td class="border-l border-gray-600">قیمت</td>
            <td class="border-l border-gray-600">تخفیف</td>
            <td>جمع</td>
          </tr>
          </thead>
          <tbody class="text-center divide-y divide-gray-600">
          <tr
              v-for="item in invoice.Items"
              :key="item.InvoiceDetailId">
            <td class="text-right w-2/5 border-l border-gray-600 pr-2 py-0.5">{{ item.Product?.Name }}</td>
            <td class="border-l border-gray-600">
              <div v-for="stockDetail in item.StockDetails"
                   :key="stockDetail.StockDetailId">
                <span>{{ stockDetail.Amount }}</span>
              </div>
            </td>
            <td class="border-l border-gray-600">
              <div v-for="stockDetail in item.StockDetails"
                   :key="stockDetail.StockDetailId">
                <span>{{ $helper.priceFormatter(stockDetail.OrgPrice, false) }}</span>
              </div>
            </td>
            <td class="border-l border-gray-600">
              <div v-for="stockDetail in item.StockDetails"
                   :key="stockDetail.StockDetailId">
                <span>{{ $helper.priceFormatter(stockDetail.DiscountPrice, false) }}</span>
              </div>
            </td>
            <td>
              <div v-for="stockDetail in item.StockDetails"
                   :key="stockDetail.StockDetailId">
                <span>{{ $helper.priceFormatter(stockDetail.JcoPrice * stockDetail.Amount, false) }}</span>
              </div>
            </td>
          </tr>

          <tr>
            <td class="py-1 border-l border-gray-600">جمع کل (قبل از تخفیف)</td>
            <td colspan="4" class="text-left pl-2">{{ $helper.priceFormatter(invoice.TotalPrice ) }}</td>
          </tr>

           <!-- Profit -->
           <tr>
            <td class="py-1 border-l border-gray-600">جمع تخفیف(سود شما)</td>
            <td colspan="4" class="text-left pl-2">
              {{ $helper.priceFormatter(invoice.ProfitPrice) }}
            </td>
          </tr>

          <!-- Transport Cost -->
          <tr>
            <td class="py-1 border-l border-gray-600">هزینه حمل</td>
            <td colspan="4" class="text-left pl-2">{{ $helper.priceFormatter(invoice.TransportCost) }}</td>
          </tr>

           <!-- PayedByCredit -->
           <tr>
            <td class="py-1 border-l border-gray-600 text-[0.5rem]" > پرداختی(کیف پول،‌درگاه اینترنتی)</td>
            <td colspan="4" class="text-left pl-2">{{ $helper.priceFormatter(invoice.PayedByCredit) }}</td>
          </tr>


          <!-- Payment Price -->
          <tr class="text-xs">
            <td class="py-1 border-l border-gray-600 font-bold text-[0.6rem] bg-gray-800 text-white">مبلغ مانده قابل پرداخت</td>
            <td colspan="4" class="text-left pl-2 font-bold bg-gray-800 text-white">
              {{ $helper.priceFormatter(invoice.UnpaidPrice) }}
            </td>
          </tr>

          <!-- Credit Payment -->
          <tr>
            <td colspan="5" class="py-4">از خرید شما متشکریم.</td>
          </tr>

          </tbody>
        </table>

      </div>

    </div>
  </teleport>
</template>

<script>
import VueQrcode from "@chenfengyuan/vue-qrcode";

export default {
  name: "InvoicePrint",
  props: ['invoice'],
  components: {VueQrcode},
}
</script>

<style scoped>

</style>