import {httpClient} from "@/API/HttpClient.js";

// Login
const login = (mobile, password) => httpClient.post(`login`, {mobile, password});
const getProfile = () => httpClient.get(`profile`);
const updateProfile = (user) => httpClient.put(`profile`, user, {params: {subSystemMenuId: 103130300}});
const postFCMToken = (fcm_token) => httpClient.post(`profile/FCM`, {fcm_token});
const changeInventory = (inventoryId) => httpClient.put(`profile/changeInventory`, {inventoryId});

const getMenu = () => httpClient.get(`menu`);

// Lookup
const getLookupCombos = (subSystemMenuId) => httpClient.get(`lookup/combo`, { params: {subSystemMenuId} });

// Invoice
const getInvoices = (filter, firstCall) => httpClient.get(`invoice`, {params: {...filter, firstCall}});
const getDistributorInvoices = (filter, firstCall) => httpClient.get(`invoice/distributor`, {params: {...filter, firstCall}});
const getInvoice = (id) => httpClient.get(`invoice/${id}`);
const acceptInvoice = (id) => httpClient.put(`invoice/${id}/accept`);
const rejectInvoice = (id, data) => httpClient.put(`invoice/${id}/reject`, data);
const supporterCancelInvoice = (id, data) => httpClient.put(`invoice/${id}/supporterCancel`, data);
const proceedInvoice = (id, data = null) => httpClient.put(`invoice/${id}/proceed`, {data});
const deliverInvoice = (id, deliveryCode) => httpClient.post(`invoice/${id}/deliver`, {deliveryCode});
const suspendInvoices = (suspend) => httpClient.post(`invoice/suspend`, suspend, {params: {subSystemMenuId: 103020201 }});
const getInvoiceLogs = (id) => httpClient.get(`invoice/${id}/log`);
const getInvoiceTransactions = (id) => httpClient.get(`invoice/${id}/transaction`);
const updateInvoiceTransaction = (id, transactionId, amount) => httpClient.put(`invoice/${id}/transaction`, {transactionId, amount});
const storeInvoicePOSData = (id, posData) => httpClient.post(`invoice/${id}/POS-data`, {posData});

const returnInvoice = (id, description, subSystemMenuId) => httpClient.put(`invoice/${id}/return`, {description, subSystemMenuId});
const backInvoice = (id, subSystemMenuId) => httpClient.put(`invoice/${id}/back`, {subSystemMenuId});
const readyToSendInvoices = (filter, firstCall) => httpClient.get(`invoice/readyToSend`, {params: {...filter, firstCall}});

// Coordination
const createCoordination = (type, id, coordination) => httpClient.post(`coordination`, {
    type,
    id, ...coordination
});

// Modify Invoice
const changeInvoice = (id, invoice) => httpClient.put(`invoice/${id}/change`, invoice);
const changeInvoiceProducts = (id, products, step) => httpClient.put(`invoice/${id}/change-products`, {products, step});
const addProductToInvoice = (id, ProductId) => httpClient.post(`invoice/${id}/add`, {ProductId});
const searchProducts = (q, inventoryId) => httpClient.get(`search`, {params: {q, inventoryId}});

const assignInvoiceToDistributor = (assignment) => httpClient.put(`invoice/assignDistributor`, assignment);
// DeliveryDateTime
const getDeliveryDateTimes = (filter) => httpClient.get(`delivery-datetime`, {params: filter});
const updateDeliveryDateTime = (id, deliveryDateTime) => httpClient.put(`delivery-datetime/${id}`, deliveryDateTime);

// StockDetail
const setIsScanned = (id, stockId) => httpClient.put(`stock-detail/${id}/scan`, {stockId});
const replaceStockDetail = (id, rejectInfo) => httpClient.post(`stock-detail/${id}/replace`, {rejectInfo});
const compensateStockDetail = (id) => httpClient.post(`stock-detail/${id}/compensate`);

// DistributorVehicle
const getAssignableDistributorVehicle = (deliveryDateTimeId) => httpClient.get(`distributor-vehicle/assignable`, {params: {deliveryDateTimeId}});
const getDistributorVehicles = (filter, firstCall) => httpClient.get(`distributor-vehicle`, {params: {...filter, firstCall}});
const getDistributorVehicle = (distributorVehicleId, deliveryDateTimeId) => httpClient.get(`distributor-vehicle/${distributorVehicleId}`, {params: {deliveryDateTimeId}});

const cancelDistributorVehicleAssignment = (distributorVehicleId, cancelAssignment) => httpClient.post(`distributor-vehicle/${distributorVehicleId}/cancelAssignment`, cancelAssignment);
const cancelDistributorVehicleInvoiceReturn = (invoiceReturnId, cancelAssignment) => httpClient.post(`distributor-vehicle/invoice-return/${invoiceReturnId}/cancel`, cancelAssignment);
const cancelDistributorVehicleInvoice = (invoiceId, cancelAssignment) => httpClient.post(`distributor-vehicle/invoice/${invoiceId}/cancel`, cancelAssignment);

const distributorVehicleAssignmentDone = (distributorAssignmentId) => httpClient.put(`distributor-vehicle/${distributorAssignmentId}/assignmentDone`);
const distributorVehicleCancelAssignmentDone = (distributorAssignmentId) => httpClient.put(`distributor-vehicle/${distributorAssignmentId}/cancelAssignmentDone`);
const getDistributorVehicleRouting = (deliveryList, inventoryLatLong) => httpClient.post(`distributor-vehicle/routing`, {deliveryList, inventoryLatLong});
const updateDistributorVehicleDeliveryOrder = (distributorAssignmentId, orderedLatLongs, routingData) => httpClient.put(`distributor-vehicle/${distributorAssignmentId}/deliveryOrder`,{orderedLatLongs, routingData});

// Distributor

// User
const getUsers = (filter, firstCall) => httpClient.get(`user`, {params: {...filter, firstCall}});
const createUser = (user, subSystemMenuId) => httpClient.post(`user`, {...user}, {params: {subSystemMenuId}})
const updateUser = (id, user, subSystemMenuId) => httpClient.put(`user/${id}`, user, {params: {subSystemMenuId}})
const assignCarToUser = (id, vehicleInventoryId, hasRole, password) => httpClient.put(`user/${id}/assignCar`, {vehicleInventoryId, hasRole, password}, {params: {subSystemMenuId: 103040201}})
const checkUserExist = (Mobile, subSystemMenuId) => httpClient.get(`user/checkExist`, {params: {Mobile, subSystemMenuId}});

// Role
const getRoles = (type) => httpClient.get(`role`, {params: {type}});

// Stock
const getStocks = (filter, firstCall) => httpClient.get(`stock`, {params: {...filter, firstCall}});
const updateStock = (id, stock) => httpClient.put(`stock/${id}`, stock);
const defectStock = (id, request) => httpClient.post(`stock/${id}/defect`, request);
const transferStocks = (destination, stocks, subSystemMenuId) => httpClient.post(`stock/transfer`, {destination, stocks}, {params: {subSystemMenuId}});

// Complaint
const getComplaints = (filter, firstCall) => httpClient.get(`complaint`, {params: {...filter, firstCall}});
const getComplaintStates = () => httpClient.get(`complaint/state`);
const getComplaint = (id) => httpClient.get(`complaint/${id}`);
const acceptComplaint = (id) => httpClient.put(`complaint/${id}/accept`);
const backComplaint = (id) => httpClient.put(`complaint/${id}/back`);
const updateComplaint = (id, complaint) => httpClient.put(`complaint/${id}`, complaint);

// Invoice-Return
const getInvoiceReturnReasons = () => httpClient.get(`invoice-return/reason`);
const getInvoiceReturns = (filter, firstCall) => httpClient.get(`invoice-return`, {params: {...filter, firstCall}});
const getInvoiceReturn = (id, type) => httpClient.get(`invoice-return/${id}`, {params: {type}});
const createInvoiceReturn = (data) => httpClient.post(`invoice-return`, data);
const acceptInvoiceReturn = (id, type) => httpClient.put(`invoice-return/${id}/accept`, {type});
const backInvoiceReturn = (id, type) => httpClient.put(`invoice-return/${id}/back`, {type});
const submitInvoiceReturn = (id, invoiceReturn) => httpClient.put(`invoice-return/${id}/submit`, invoiceReturn);
const deliverInvoiceReturnToInventory = (id) => httpClient.post(`invoice-return/${id}/deliverToInventory`);
const suspendInvoiceReturns = (suspend) => httpClient.post(`invoice-return/suspend`, suspend);

const getInvoiceReturnReturnableProducts = (id) => httpClient.get(`invoice-return/${id}/returnableProducts`);
const sendInvoiceReturnDeliveryCode = (id, sendType) => httpClient.post(`invoice-return/${id}/sendDeliveryCode`, {sendType});
const cancelInvoiceReturn = (id, invoiceReturn) => httpClient.put(`invoice-return/${id}/cancel`, invoiceReturn);

const scanInvoiceReturnDetail = (id, stockId) => httpClient.put(`invoice-return-detail/${id}/scan`, {stockId});
const defectInvoiceReturnDetail = (id) => httpClient.put(`invoice-return-detail/${id}/defect`);
const getInvoiceReturnLogs = (id) => httpClient.get(`invoice-return/${id}/log`);

// Vehicle
const getVehicles = (filter, firstCall) => httpClient.get(`vehicle-inventory`, {params: {...filter, firstCall}});
const createVehicle = (vehicle) => httpClient.post(`vehicle-inventory`, vehicle);
const updateVehicle = (id, vehicle) => httpClient.put(`vehicle-inventory/${id}`, vehicle);
const deleteVehicle = (id) => httpClient.delete(`vehicle-inventory/${id}`);
const checkVehicleExist = (Plaque) => httpClient.get(`vehicle/checkExist`, {params: {Plaque}});

// Location
const getLocation = (filter) => httpClient.get(`location`, {params: filter});
const sendLocation = (latlng) => httpClient.post(`location`, latlng);

// Basket Info
const getBasketInfo = () => httpClient.get(`basket`);

// Request
const getRequestTypes = (filter) => httpClient.get(`request/type`, {params: filter});
const getRequests = (filter, firstCall) => httpClient.get(`request`, {params: {...filter, firstCall}});
const createRequest = (request) => httpClient.post(`request`, request);
const answerRequest = (requestId, answer) => httpClient.put(`request/${requestId}`, answer);

// Wallet Request
const getWalletRequests = (filter, firstCall) => httpClient.get(`request/wallet`, {params: {...filter, firstCall}});

// Message
const getMessages = (filter) => httpClient.get(`message`, {params: filter});
const seenMessages = (id) => httpClient.post(`message/${id}/seen`);

// Campaign
const getCampaigns = (filter) => httpClient.get(`campaign`, {params: filter});
const getCampaign = (id, filter) => httpClient.get(`campaign/${id}`, {params: filter});
const createCampaign = (campaign) => httpClient.post(`campaign`, campaign);
const updateCampaign = (id, campaign) => httpClient.put(`campaign/${id}`, campaign);
const deleteCampaign = (id) => httpClient.delete(`campaign/${id}`);
// Campaign Product
const addProductToCampaign = (campaignId, products) => httpClient.post(`campaign/${campaignId}/product`, {products});
const removeProductFromCampaign = (campaignId, productId) => httpClient.delete(`campaign/${campaignId}/product/${productId}`);
// Document
const getDocuments = (filter) => httpClient.get(`document`, {params: filter});
const getDocument = (id) => httpClient.get(`document/${id}`);
const storeDocument = (id, documentData) => httpClient.post(`document/${id}`, {documentData});
// Stock-Defect
const getStockDefects = (filter, firstCall) => httpClient.get(`stock-defect`, {params: {...filter, firstCall}});
const transferStockDefects = (destination, stockDefects) => httpClient.post(`stock-defect/transfer`, {destination, stockDefects});

// TransferToCentral
const getTransferToCentral = (filter, firstCall) => httpClient.get(`TransferToCentral`, {params: {...filter, firstCall}});

// Shift
const getShifts = (filter) => httpClient.get(`shift`, {params: filter});
const getShift = (id) => httpClient.get(`shift/${id}`);
const createShift = (shift) => httpClient.post(`shift`, shift);
const updateShift = (id, shift) => httpClient.put(`shift/${id}`, shift);
const deleteShift = (id) => httpClient.delete(`shift/${id}`);

// Shift Distributors
const getDistributorsForShift = (id) => httpClient.get(`shift/${id}/distributor`, {params: {subSystemMenuId: 103040301}});
const addDistributorToShift = (id, distributor) => httpClient.post(`shift/${id}/distributor`, distributor);
const toggleDistributorInShift = (id, distributorId, isActive) => httpClient.put(`shift/${id}/distributor/${distributorId}`, {isActive}, {params: {subSystemMenuId: 103040301}});
const removeDistributorFromShift = (id, distributorId) => httpClient.delete(`shift/${id}/distributor/${distributorId}`, {params: {subSystemMenuId: 103040301}});

// Report
const getReport = (type1, type2, data) => httpClient.get(`report`, {params: {type1, type2, ...data}});

// Comment
const getComments = (filter, firstCall ) => httpClient.get('comment', {params: {...filter, firstCall}})
const updateComment = (id, type) => httpClient.put(`comment/${id}`, {type})

// HolidayCalendar
const getHolidayCalendar = (filter) => httpClient.get('holiday-calendar', {params: filter})
const createHolidayCalendar = (workDay) => httpClient.post('holiday-calendar', workDay)
const deleteHolidayCalendar = (id) => httpClient.delete(`holiday-calendar/${id}`)

const uploadImage = (image) => httpClient.post(`image`, image)

const getPriceList = (filter) => httpClient.get(`price-list`, {params: filter})
const getPriceDetail = (id, filter) => httpClient.get(`price-list/${id}`, {params: filter})
const activatePrice = (id) => httpClient.put(`price-list/${id}`)

export {
    uploadImage,
    login,
    getProfile,
    updateProfile,
    changeInventory,
    getMenu,
    getLookupCombos,
    postFCMToken,
    getInvoice,
    rejectInvoice,
    supporterCancelInvoice,
    acceptInvoice,
    getInvoices,
    getDistributorInvoices,
    proceedInvoice,
    deliverInvoice,
    suspendInvoices,
    getInvoiceLogs,
    getInvoiceTransactions,
    updateInvoiceTransaction,
    storeInvoicePOSData,
    returnInvoice,
    readyToSendInvoices,
    createCoordination,
    changeInvoice,
    assignInvoiceToDistributor,
    addProductToInvoice,
    searchProducts,
    backInvoice,
    setIsScanned,
    replaceStockDetail,
    compensateStockDetail,
    getAssignableDistributorVehicle,
    getDistributorVehicles,
    getDistributorVehicle,
    getDistributorVehicleRouting,
    updateDistributorVehicleDeliveryOrder,
    cancelDistributorVehicleAssignment,
    cancelDistributorVehicleInvoiceReturn,
    cancelDistributorVehicleInvoice,
    getUsers,
    createUser,
    updateUser,
    checkUserExist,
    getRoles,
    getStocks,
    updateStock,
    defectStock,
    transferStocks,
    getComplaints,
    getComplaint,
    acceptComplaint,
    backComplaint,
    getComplaintStates,
    updateComplaint,
    createInvoiceReturn,
    getInvoiceReturnReasons,
    getInvoiceReturns,
    getInvoiceReturn,
    acceptInvoiceReturn,
    backInvoiceReturn,
    getInvoiceReturnReturnableProducts,
    sendInvoiceReturnDeliveryCode,
    cancelInvoiceReturn,
    getLocation,
    sendLocation,
    submitInvoiceReturn,
    deliverInvoiceReturnToInventory,
    suspendInvoiceReturns,
    scanInvoiceReturnDetail,
    getInvoiceReturnLogs,
    defectInvoiceReturnDetail,
    getVehicles,
    createVehicle,
    updateVehicle,
    deleteVehicle,
    checkVehicleExist,
    getDeliveryDateTimes,
    assignCarToUser,
    getBasketInfo,
    getRequestTypes,
    getRequests,
    answerRequest,
    getWalletRequests,
    createRequest,
    getCampaigns,
    getCampaign,
    createCampaign,
    updateCampaign,
    deleteCampaign,
    addProductToCampaign,
    removeProductFromCampaign,
    getMessages,
    seenMessages,
    getDocuments,
    getDocument,
    storeDocument,
    getStockDefects,
    getTransferToCentral,
    transferStockDefects,
    getReport,
    distributorVehicleAssignmentDone,
    distributorVehicleCancelAssignmentDone,
    getComments,
    updateComment,
    getShifts,
    getShift,
    createShift,
    updateShift,
    deleteShift,
    addDistributorToShift,
    getDistributorsForShift,
    removeDistributorFromShift,
    toggleDistributorInShift,
    getHolidayCalendar,
    createHolidayCalendar,
    deleteHolidayCalendar,
    updateDeliveryDateTime,
    getPriceList,
    getPriceDetail,
    activatePrice,
    changeInvoiceProducts,
}
