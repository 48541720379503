<template>
  <div class="intro-y !sticky top-0">
    <div class="flex justify-between items-center">
      <div>
        <h1>{{ distributorAssignment.DistributorName }}</h1>
        <h6 class="mt-1">
          {{ distributorAssignment.DeliveryDateTime?.DeliveryDate }}
          -
          {{ distributorAssignment.DeliveryDateTime?.Description }}
        </h6>
      </div>

      <div class="border border-yellow-500 p-2 rounded-lg">
        وضعیت تخصیص:
        <span>{{ distributorAssignment.State?.Name ?? '-' }}</span>
      </div>

      <div class="flex">
        <VButton @click="cancelAllAssignments" :disabled="invoiceInvoiceReturns.length === 0">لغو تخصیص ها
        </VButton>

        <VButton @click="confirmCancelAssignmentModal = true" :disabled="distributorAssignment.State?.LookupId !== 2">
          لغو اتمام تخصیص
        </VButton>

        <VButton
            :disabled="distributorAssignment.State?.LookupId === 4 || ([2,3].includes(distributorAssignment.State?.LookupId) && distributorAssignment.RoutingData) || invoiceInvoiceReturns.length === 0"
            @click="confirmAssignmentModal = true">مسیریابی و اتمام تخصیص
        </VButton>
      </div>

    </div>

    <div id="map" class="z-0 mt-4 h-[300px]"></div>
  </div>

  <VCard class="!bg-transparent mt-4 text-slate-600" no-padding>

    <div ref="table"></div>

  </VCard>

  <VButton class="mt-4 w-auto mr-auto" v-if="showSaveButton" @click="submitRowOrders">ذخیره مسیر و اتمام تخصیص</VButton>

  <ConfirmModal
      v-model="confirmAssignmentModal"
      title="اتمام تخصیص"
      text="از اتمام تخصیص مطمئن هستید ؟"
      @confirm="assignmentDone"
      @cancel="confirmAssignmentModal = false"
  />

  <ConfirmModal
      v-model="confirmCancelAssignmentModal"
      title="لغو اتمام تخصیص"
      text="از لغو اتمام تخصیص مطمئن هستید ؟"
      @confirm="cancelAssignmentDone"
      @cancel="confirmCancelAssignmentModal = false"
  />

  <CancelAssignmentModal
      v-model="cancelAssignmentModal"
      :type="selectedAssignment.type"
      :cancelable-id="selectedAssignment.id"
      @submit="assignmentCanceled"
  />
</template>

<script>
import VCard from "@/components/General/VCard";
import {
  distributorVehicleAssignmentDone, distributorVehicleCancelAssignmentDone,
  getDistributorVehicle,
  getDistributorVehicleRouting,
  updateDistributorVehicleDeliveryOrder
} from "@/API/API";
import ConfirmModal from "@/components/Modal/General/ConfirmModal";
import {TabulatorFull as Tabulator} from "tabulator-tables";
import VButton from "@/components/General/VButton";
import L from "leaflet";
import CancelAssignmentModal from "@/components/Modal/DistributorManager/CancelAssignmentModal.vue";
import {mapGetters} from "vuex";

export default {
  name: "DistributorAssignmentDetail",
  components: {CancelAssignmentModal, VButton, ConfirmModal, VCard},
  data() {
    return {
      deliveryTimes: [],
      distributorAssignment: {},

      routingData: '',
      polyLine: {},
      markerLayer: {},

      showSaveButton: false,
      cancelAssignmentModal: false,
      confirmAssignmentModal: false,
      confirmCancelAssignmentModal: false,

      selectedAssignment: {
        id: '',
        type: ''
      }
    }
  },
  created() {
    this.getInvoices()
  },
  computed: {
    ...mapGetters(['selectedInventory']),
    invoiceInvoiceReturns() {
      if (this.distributorAssignment.DistributorAssignmentId)
        return this.distributorAssignment.Invoices.concat(this.distributorAssignment.InvoiceReturns)
      else
        return []
    },
    inventoryLatLong() {
      let inventory = this.$store.getters.userInventories.find(inventory => inventory.InventoryId == this.$store.getters.selectedInventory);
      return inventory.Lat + ',' + inventory.Long
    },
  },
  mounted() {
    this.initTable()
    // after Table DOM & data Loaded
    this.tabulator.on("renderComplete", () => {
      // Show Button
      let showButtons = document.querySelectorAll("div[data-btn-type='show']")
      showButtons.forEach(element =>
          element.onclick = () => {
            if (element.dataset.type === 'Invoice')
              this.$router.push({name: 'DistributorManagerInvoiceDetail', params: {id: element.dataset.id}})
            else if (element.dataset.type === 'InvoiceReturn')
              this.$router.push({name: 'DistributorManagerInvoiceReturnDetail', params: {id: element.dataset.id}})
          })
      // Cancel Buttons
      let cancelButtons = document.querySelectorAll("div[data-btn-type='cancel']")
      cancelButtons.forEach(element =>
          element.onclick = () => {
            this.cancelAssignmentModal = true
            this.selectedAssignment.id = element.dataset.id
            if (element.dataset.type === 'Invoice')
              this.selectedAssignment.type = 'invoice'
            else if (element.dataset.type === 'InvoiceReturn')
              this.selectedAssignment.type = 'invoice-return'
          })
    });

    this.tabulator.on('rowMoved', async () => {
      this.showSaveButton = true
      this.tabulator.getRows().forEach(row => row.update({DeliveryOrder: row.getPosition()}))

      this.map.removeLayer(this.polyLine)
      this.routingData = (await getDistributorVehicleRouting(this.deliveryList(), this.inventoryLatLong)).data.encodedPolyline
      this.polyLine = L.Polyline.fromEncoded(this.routingData, {color: 'blue'}).addTo(this.map)
    });

    this.initMap()
  },
  methods: {
    async getInvoices() {
      this.tabulator?.alertManager.alert('در حال بارگذاری ....')
      this.distributorAssignment = (await getDistributorVehicle(this.$route.params.id)).data.data
      this.tabulator.alertManager.clear();
      this.addMapMarker()
      this.refreshTable()
    },
    cancelAllAssignments() {
      this.cancelAssignmentModal = true
      this.selectedAssignment.id = this.distributorAssignment.DistributorAssignmentId
      this.selectedAssignment.type = 'all'
    },
    assignmentCanceled() {
      this.cancelAssignmentModal = false;
      if (this.selectedAssignment.type === 'all') {
        this.$router.back()
      } else {
        this.getInvoices()
      }
    },
    assignmentDone() {
      distributorVehicleAssignmentDone(this.distributorAssignment.DistributorAssignmentId)
          .then(resp => {
            this.$infoModal.value = resp.data.message
            this.confirmAssignmentModal = false
            this.getInvoices()
          })
    },
    cancelAssignmentDone() {
      distributorVehicleCancelAssignmentDone(this.distributorAssignment.DistributorAssignmentId)
          .then(resp => {
            this.$infoModal.value = resp.data.message
            this.confirmCancelAssignmentModal = false
            this.distributorAssignment = resp.data.data;
            this.addMapMarker();
          })
    },
    initMap() {
      this.map = L.map('map', {
        center: [36.310699, 59.599457],
        zoom: 11,
        zoomControl: false,
        fullscreenControl: true,
      })
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(this.map);

      this.markerLayer = L.layerGroup().addTo(this.map);
      this.oms = new window.OverlappingMarkerSpiderfier(this.map);
      let popup = new L.Popup({closeButton: false});

      this.oms.addListener('click', marker => {
        popup.setContent(marker.desc);
        popup.setLatLng(marker.getLatLng());
        this.map.openPopup(popup);
      });
    },
    addMapMarker() {
      this.map.removeLayer(this.polyLine)
      this.markerLayer.clearLayers()

      if (this.distributorAssignment.RoutingData)
        this.polyLine = L.Polyline.fromEncoded(this.distributorAssignment.RoutingData, {color: 'red'}).addTo(this.map)

      let invoiceIcon = L.icon({
        iconUrl: require('/src/assets/img/map-marker-blue.png'),
        iconSize: [30, 30],
        // popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
      });

      let invoiceReturnIcon = L.icon({
        iconUrl: require('/src/assets/img/map_marker.png'),
        iconSize: [30, 30],
      });

      let buildingIcon = L.icon({
        iconUrl: require('/src/assets/img/map_marker_building.png'),
        iconSize: [30, 30],
      });

      // Inventory Building Marker
      let inventory = this.$store.getters.userInventories.find(inventory => inventory.InventoryId == this.$store.getters.selectedInventory);
      let marker = L.marker([inventory.Lat, inventory.Long], {icon: buildingIcon}).addTo(this.markerLayer)
      marker.desc = 'انبار'
      this.oms.addMarker(marker)

      // Invoice|InvoiceReturn Markers
      this.invoiceInvoiceReturns.forEach(invoice => {
        let marker = L.marker([invoice.Address.Lat, invoice.Address.Long], {
          icon: invoice.InvoiceReturnId ? invoiceReturnIcon : invoiceIcon
        }).addTo(this.markerLayer)
        marker.desc =
            `<div class="flex flex-col items-center">
                <span>${invoice.InvoiceReturnId ? 'مرجوعی' + invoice.InvoiceReturnId : 'سفارش' + invoice.InvoiceId}</span>
                <a href="${invoice.InvoiceReturnId ? '#/distributor/invoice-return/' + invoice.InvoiceReturnId
                : '#/distributor/invoice/' + invoice.InvoiceId}#/distributor/invoice/${invoice.InvoiceId}">
                جزئیات
                </a>
            </div>`
        this.oms.addMarker(marker)
      })
    },
    refreshTable() {
      this.tabulator.setData(this.invoiceInvoiceReturns)
      window.scrollTo(0, 0)
    },
    initTable() {
      this.tabulator = new Tabulator(this.$refs.table, {
        selectable: false,
        layout: "fitDataFill",
        responsiveLayout: "collapse",
        movableRows: true,
        columns: [
          {
            formatter: "responsiveCollapse",
            width: 40,
            minWidth: 30,
            hozAlign: "center",
            resizable: false,
            headerSort: false
          },
          {
            rowHandle: true,
            formatter: "handle",
            headerSort: false,
            frozen: true,
            width: 30,
            minWidth: 30
          },
          {
            title: 'شناسه',
            field: 'InvoiceId',
            formatter: cell => cell.getData().InvoiceReturnId ?? cell.getData().InvoiceId
          },
          {
            title: 'اولویت',
            field: 'DeliveryOrder',
          },
          {
            title: 'نوع',
            field: 'type',
            formatter: cell => {
              let isInvoiceReturn = !!cell.getData().InvoiceReturnId
              return `<div class="badge ${isInvoiceReturn ? 'bg-red-500' : 'bg-green-500'}">
                         <span>${isInvoiceReturn ? 'مرجوعی' : 'سفارش'}</span>
                      </div>`
            }
          },
          {
            title: 'حجم',
            field: 'BasketCount',
            formatter: cell => cell.getValue() + ' سبد'
          },
          {
            title: 'آدرس',
            field: 'Address.FullAddress',
            formatter: cell => `<span class="whitespace-pre-wrap">${cell.getData().Address.FullAddress}</span>`
          },
          {
            title: 'وضعیت',
            field: 'State.Name',
            headerSort: false,
            formatter: cell => {
              return `<span class="badge-outline ${this.$helper.renderInvoiceState(cell.getData().State.LookupId)}">
                        ${cell.getData().State.Name}
                    </span>`
            }
          },
          {
            title: 'عملیات',
            field: 'InvoiceId',
            resizable: false,
            headerSort: false,
            vertAlign: 'middle',
            formatter: (cell) => {
              return `<div class="flex">
                        <div class="flex items-center text-blue-600 cursor-pointer" data-type="${cell.getData().InvoiceReturnId ? 'InvoiceReturn' : 'Invoice'}"
                             data-btn-type="show" data-id="${cell.getData().InvoiceReturnId ?? cell.getData().InvoiceId}">
                             <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-blue-700">
                                  <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                  <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                             </svg>
                             <span class="mr-1 text-xs">نمایش</span>
                        </div>
                        <div class="flex items-center text-red-500 mr-4 cursor-pointer" data-type="${cell.getData().InvoiceReturnId ? 'InvoiceReturn' : 'Invoice'}"
                             data-btn-type="cancel" data-id="${cell.getData().InvoiceReturnId ?? cell.getData().InvoiceId}">
                             <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m6.75 12H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                             </svg>
                             <span class="mr-1 text-xs">لغو تخصیص</span>
                        </div>
                      </div>`
            }
          },
        ],
      });
    },
    deliveryList() {
      return this.tabulator.getRows().map(row => ({
        DeliveryOrder: row.getPosition(),
        Id: row.getData().InvoiceReturnId ?? row.getData().InvoiceId,
        Type: row.getData().InvoiceReturnId ? 8 : 1,
        Lat: row.getData().Address.Lat,
        Long: row.getData().Address.Long,
      }))
    },
    submitRowOrders() {
      updateDistributorVehicleDeliveryOrder(this.distributorAssignment.DistributorAssignmentId, this.deliveryList(), this.routingData)
          .then(resp => {
            this.$infoModal.value = resp.data.message
            this.showSaveButton = false
            this.getInvoices()
          })
    }
  },
}
</script>

<style scoped>

</style>